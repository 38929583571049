html {
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
}
*,
*:before,
*:after {
  -webkit-box-sizing: inherit;
  -moz-box-sizing: inherit;
  box-sizing: inherit;
}

html,
body,
#root,
.rs-container {
  height: 100%;
}

tbody tr:hover {
  background: #fcfcfc;
}

td,
th {
  padding: 5px;
}

tbody tr:target {
  outline: auto 4px -webkit-focus-ring-color;
}

/* RSUITE customizations */
.rs-table-hover .rs-table-row:hover .rs-table-cell,
.rs-table-hover .rs-table-row:hover .rs-table-cell-group,
.rs-table-hover .rs-table-row:not(.rs-table-row-header):hover {
  background: #fcfcfc;
}

.rs-table .header-cell-group .rs-table-cell-content {
  padding: 0;
}

.rs-table .header-cell-group-title,
.rs-table .header-cell-group-subtitle > * {
  padding: 10px;
  border-bottom: 1px solid #f2f2f5;
}

.rs-table .header-cell-group-subtitle {
  display: flex;
}

.rs-table .header-cell-group-subtitle > * {
  flex: 1;
  border-right: 1px solid #f2f2f5;
}
