.jodit_container .jodit_workplace,
.jodit_container .jodit_toolbar,
.jodit_container .jodit_statusbar,
.jodit_container .jodit_toolbar>li.jodit_toolbar_btn.jodit_toolbar_btn-separator,
.jodit_container .jodit_toolbar>li.jodit_toolbar_btn.jodit_toolbar_btn-break {
  border-color: #e5e5ea;
}
.jodit_container .jodit_toolbar,
.jodit_container .jodit_statusbar {
  background-color: #ffffff;
}
.jodit_container .jodit_icon,
.jodit_container .jodit_toolbar .jodit_toolbar_btn>a {
  fill: #646464;
  color: #646464;
}
.jodit_container .jodit_wysiwyg {
  color: #000000;
}
.jodit_container .jodit_placeholder {
  color: #00000080;
}
.jodit_container .jodit_toolbar>li.jodit_toolbar_btn.jodit_with_dropdownlist .jodit_with_dropdownlist-trigger {
  border-top-color: #646464;
}
